import restClient from './rest-client';

export enum CollaborationStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export type Collaboration = {
    category?: string;
    categoryName: string;
    collaborationCode?: string;
    collaborationId: string;
    collaborationStatus: CollaborationStatus;
    conversationId: number;
    createdByName?: string;
    description?: string;
    lastUpdated?: number;
    name: string;
    order: number;
    slug: string;
    startTime?: number;
    taxonomy: string;
};

type CollaborationResponse = {
    category_name: string;
    category: string;
    collaboration_code: string | null;
    collaboration_id: string;
    collaboration_status: CollaborationStatus;
    conversation_id: string;
    created_by_name: string | null;
    description: string | null;
    last_updated: number | null;
    name: string | null;
    order: number;
    slug: string | null;
    start_time: number;
    taxonomy: string | null;
};

const transformCollaborationResponse = ({
    category_name,
    category,
    collaboration_code,
    collaboration_id,
    collaboration_status,
    conversation_id,
    created_by_name,
    description,
    name,
    order,
    last_updated,
    slug,
    start_time,
    taxonomy,
}: CollaborationResponse): Collaboration => ({
    category,
    categoryName: category_name,
    collaborationCode: collaboration_code ?? undefined,
    collaborationId: collaboration_id,
    collaborationStatus: collaboration_status,
    conversationId: Number(conversation_id),
    createdByName: created_by_name ?? undefined,
    description: description ?? '',
    name: name ?? '',
    order,
    slug: slug ?? '',
    startTime: start_time ? Number(start_time) : undefined,
    lastUpdated: last_updated ? Number(last_updated) : undefined,
    taxonomy: taxonomy ?? '',
});

const transformGetCollaborationListResponse = (
    collaborations: CollaborationResponse[]
) =>
    collaborations
        ?.map(transformCollaborationResponse)
        .sort((a, b) => (a.order < b.order ? 1 : a.order > b.order ? -1 : 0));

type ValidateCollaborationCodeResponse = {
    collaboration_id: string;
    is_collaboration_code_valid: boolean;
};

type ValidateCollaborationCode = {
    collaborationId: string;
    isCollaborationCodeValid: boolean;
};

const transformValidateCollaborationCodeResponse = ({
    collaboration_id,
    is_collaboration_code_valid,
}: ValidateCollaborationCodeResponse) => ({
    collaborationId: collaboration_id,
    isCollaborationCodeValid: is_collaboration_code_valid,
});

type RestartCollaborationResponse = {
    collaboration_id: string;
    collaboration_code: string;
};

const collaborationClient = {
    /**
     * Create collaboration
     */
    createCollaboration: (conversation_id: number): Promise<Collaboration> =>
        restClient
            .post<unknown, CollaborationResponse>('/createCollaboration', {
                conversation_id,
            })
            .then(transformCollaborationResponse),

    /**
     * Copy collaboration
     */
    copyCollaboration: (id: string): Promise<Collaboration> =>
        restClient
            .get<CollaborationResponse>('/copyCollaboration?id=' + id, false)
            .then(transformCollaborationResponse),

    /**
     * My collaborations
     */
    myCollaborations: (): Promise<Collaboration[]> =>
        restClient
            .get<CollaborationResponse[]>('/myCollaborations', false)
            .then(transformGetCollaborationListResponse),

    /**
     * End collaboration
     */
    endCollaboration: (id: string) =>
        restClient.get('/endCollaboration?id=' + id, false),

    /**
     * Restart collaboration
     */
    restartCollaboration: (id: string): Promise<RestartCollaborationResponse> =>
        restClient.get('/restartCollaboration?id=' + id, false),

    /**
     * Ping API to set `last_updated`
     */
    collaborationLastUpdated: (id: string) =>
        restClient.get('/collaborationLastUpdated?id=' + id, false),

    /**
     * Get collaboration
     */
    getCollaboration: (id: string): Promise<Collaboration> =>
        restClient
            .get<CollaborationResponse>('/getCollaboration?id=' + id, false)
            .then(transformCollaborationResponse),

    /**
     * Get collaboration by slug
     */
    getCollaborationBySlug: (slug: string): Promise<Collaboration> =>
        restClient
            .get<CollaborationResponse>(
                '/getCollaborationBySlug?slug=' + slug,
                false
            )
            .then(transformCollaborationResponse),

    /**
     * Get collaboration with code
     */
    getCollaborationWithCode: (code: string): Promise<Collaboration> =>
        restClient
            .get<CollaborationResponse>(
                '/getCollaborationWithCode?code=' + code,
                false
            )
            .then(transformCollaborationResponse),

    /**
     * Delete collaboration
     */
    deleteCollaboration: (id: string) =>
        restClient.get('/deleteCollaboration?id=' + id, false),

    /**
     * Validate collaboration code. Intended for participants in collaborations, to verify the code and
     * return required collaboration data.
     */
    validateCollaborationCode: (
        code: string
    ): Promise<ValidateCollaborationCode> =>
        restClient
            .get<ValidateCollaborationCodeResponse>(
                '/validateCollaborationCode?code=' + code,
                true
            )
            .then(transformValidateCollaborationCodeResponse),
};

export default collaborationClient;
