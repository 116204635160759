import {
    Conversation,
    ConversationResponse,
    ConversationWithCategory,
    FeaturedConversation,
    FeaturedConversationResponse,
    filterFeaturedConversations,
    mapConversations,
    mapConversationsWithCategories,
    mapFeaturedConversations,
    mapQuestion,
    mapQuestions,
    QuestionResponse,
    mapMyConversationsWithCategories,
    mapMyConversationWithCategories,
    mapConversation,
    UserConversationTaxonomy,
} from '_types';
import { Question } from '../_types/question';
import restClient from './rest-client';

export interface FavouriteConversation {
    conversation_id: number;
}
export interface FavouriteQuestion {
    question_id: number;
}
export interface FavouriteConversationResponse {
    token: string;
    user_display_name: string;
    user_email: string;
    user_nicename: string;
}

export interface FavouriteQuestionResponse {
    token: string;
    user_display_name: string;
    user_email: string;
    user_nicename: string;
}

export interface MyConversation {
    conversation_id?: number;
    name: string;
    description: string;
    color_option: number;
    public: boolean;
    pro_conversation: boolean;
    questions: { question_id: number }[];
}

export interface QuestionTokenResponse {
    token: string;
    question_id: number;
}

const mapFavouriteConversationResponse = ({
    token,
}: FavouriteConversationResponse): string => token;

const mapFavouriteQuestionResponse = ({
    token,
}: FavouriteQuestionResponse): string => token;

const CONFIG = {
    baseUrl: '/wp-json/fuelbox/v1',
};

export default {
    questionCanBeShared: (question_id: number): Promise<boolean> => {
        return restClient.post('/canBeShared', {
            question_id: question_id,
        });
    },
    getSharedLinkToken: (
        question_id: number
    ): Promise<QuestionTokenResponse> => {
        return restClient.post('/getSharedLinkToken', {
            question_id: question_id,
        });
    },
    getQuestion: (id: number, token: string): Promise<Question> => {
        return restClient
            .get<QuestionResponse[]>(
                '/getQuestion?question_id=' + id + '&token=' + token,
                false
            )
            .then(mapQuestion);
    },
    getQuestions: (
        cacheEnabled: boolean,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params?: any
    ): Promise<Question[]> => {
        return restClient
            .get<QuestionResponse[]>(
                '/getCardsByTaxonomy',
                cacheEnabled,
                params
            )
            .then(mapQuestions);
    },
    getRandomQuestions: (): Promise<Question[]> => {
        return restClient
            .get<QuestionResponse[]>('/random-cards', false)
            .then(mapQuestions)
            .catch(() => []);
    },
    getNewlyAddedConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/getNewlyAddedConversations', false)
            .then(mapConversationsWithCategories);
    },
    getFeaturedConversations: (): Promise<FeaturedConversation[]> => {
        return restClient
            .get<FeaturedConversationResponse[]>(
                '/getFeaturedConversations',
                true
            )
            .then(mapFeaturedConversations)
            .then(filterFeaturedConversations);
    },
    getThemes: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/getThemes', true)
            .then(mapConversations);
    },
    getConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/getConversations', true)
            .then(mapConversations);
    },

    getConversation: (
        slug: string,
        taxonomy: string
    ): Promise<Conversation> => {
        const isUserConversation =
            taxonomy === UserConversationTaxonomy.en ||
            taxonomy === UserConversationTaxonomy.nb;
        return restClient
            .get<ConversationResponse>(
                '/getConversation',
                !isUserConversation,
                {
                    slug,
                    taxonomy,
                }
            )
            .then(mapConversation);
    },

    //FAVOURITE CONVERSATIONS
    getMyFavouriteConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/myFavouriteConversations', false)
            .then(mapConversationsWithCategories);
    },
    addFavouriteConversation: (
        favouriteConversation: FavouriteConversation
    ): Promise<string> => {
        return restClient
            .post<FavouriteConversation, FavouriteConversationResponse>(
                '/addFavouriteConversation',
                favouriteConversation,
                CONFIG
            )
            .then(mapFavouriteConversationResponse);
    },
    removeFavouriteConversation: (
        favouriteConversation: FavouriteConversation
    ) => {
        return restClient.post<
            FavouriteConversation,
            FavouriteConversationResponse
        >('/removeFavouriteConversation', favouriteConversation, CONFIG);
    },

    //FAVOURITE QUESTIONS
    getMyFavouriteQuestions: (): Promise<Question[]> => {
        return restClient
            .get<QuestionResponse[]>('/myFavouriteQuestions', false)
            .then(mapQuestions);
    },
    addFavouriteQuestion: (
        favouriteQuestion: FavouriteQuestion
    ): Promise<string> => {
        return restClient
            .post<FavouriteQuestion, FavouriteQuestionResponse>(
                '/addFavouriteQuestion',
                favouriteQuestion,
                CONFIG
            )
            .then(mapFavouriteQuestionResponse);
    },
    removeFavouriteQuestion: (favouriteQuestion: FavouriteQuestion) => {
        return restClient.post<FavouriteQuestion, FavouriteQuestionResponse>(
            '/removeFavouriteQuestion',
            favouriteQuestion,
            CONFIG
        );
    },

    //LIBRARY
    getMyConversations: (): Promise<ConversationWithCategory[]> => {
        return restClient
            .get<ConversationResponse[]>('/myConversations', false)
            .then(mapMyConversationsWithCategories);
    },

    createConversation: (
        conversation: MyConversation
    ): Promise<ConversationWithCategory> => {
        return restClient
            .post<MyConversation, ConversationResponse>(
                '/createMyConversation',
                conversation
            )
            .then(mapMyConversationWithCategories);
    },

    updateMyConversation: (
        conversation: MyConversation
    ): Promise<ConversationWithCategory> => {
        return restClient
            .put<MyConversation, ConversationResponse>(
                '/updateMyConversation',
                conversation
            )
            .then();
    },

    removeMyConversation: (conversation_id: string): Promise<unknown> => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        return restClient.post<{}, ConversationResponse[]>(
            '/removeMyConversation',
            {
                conversation_id,
            }
        );
    },

    addQuestionsToMyConversation: (
        conversation_id: number,
        question_ids: number[]
    ): Promise<string> => {
        return (
            restClient
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .post<any, any>(
                    '/addQuestionsToMyConversation',
                    {
                        conversation_id,
                        question_ids,
                    },
                    CONFIG
                )
                .then(mapFavouriteQuestionResponse)
        );
    },
};
