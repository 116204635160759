import restClient from './rest-client';
import { Deck, DeckResponse, mapDeckResponse } from '_types';

export default {
    // FIXME: this endpoint should be moved to /wp-json/fuelbox/v1
    // also Deck shape should match FeaturedConversationShape
    // Using this until appropriate changes are implemented in BACKEND
    getFreeDecks: (): Promise<Deck[]> => {
        return restClient
            .get<DeckResponse[]>(
                '/freemium/categories',
                true,
                { per_page: 100 },
                undefined,
                { baseUrl: '/wp-json/fuelbox/v1' }
            )
            .then(mapDeckResponse);
    },
};
