import { routesNO, RouteList, routesEN } from 'api/routes';
import { Layout } from 'components/layout';
import { Loading } from 'components/loading';
import { SEO } from 'components/seo';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWindowScrollPosition } from 'services/get-scroll-pos';
import {
    scrollPosSelectorProBrowsePage,
    setScrollPosProBrowsePage,
    SetScrollPosActionProBrowsePage,
    languageSelector,
} from 'store-modules/app-settings';
import { Language } from '_types/language';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import css from './pro-browse-conversations.module.scss';
import proClient from 'api/pro-client';
import {
    Conversation,
    ConversationWithCategory,
    deckParentPages,
    User,
} from '_types';
import { Tree, buildTreeFromArray } from 'utils/tree-utils';
import { ORGANIZATIONS_CATEGORY } from 'services/conversation-service';
import { Container } from 'components/container';
import { ConversationTree } from 'professional/components';
import {
    ConversationCard,
    OrganizationCard,
} from 'professional/components/cards';
import { BackButton } from 'components/back-button';
import { useWindowSize } from 'hooks';
import { setUserState, userSelector } from 'store-modules/user';
import userClient from 'api/user-client';
import Decorator from 'components/conversation-card/decorator';
import { useNavigate } from 'react-router-dom';
import useConversationSelector from 'hooks/use-conversation-selector';
import useTypedParams from 'hooks/use-typed-params';
import { ThemeCard } from 'components/conversation-card';
import classNames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';
import { ConversationCard as PremiumConversationCard } from './../../../components/conversation-card';

interface Params {
    categorySlug: string;
    topLevelSlug: string;
}

interface ViewCopy {
    pageTitle: string;
    newConversationsTitle: string;
}

const nbCopy: ViewCopy = {
    pageTitle: 'Hjem',
    newConversationsTitle: 'Nyeste samtaler',
};

const enCopy: ViewCopy = {
    pageTitle: '',
    newConversationsTitle: 'New conversations',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const isOrganizationCategory = (categorySlug: string): boolean =>
    categorySlug === ORGANIZATIONS_CATEGORY.slug;

const getOrganizationConversationTree = async (
    slug: string,
    organizationName: string,
    user: User
): Promise<Tree<Conversation> | undefined> => {
    const categories = await proClient.getOrgCategories(organizationName, user);

    const tree = buildTreeFromArray(categories);

    if (!slug) return tree;

    const flatMapTree = tree.children.flatMap((node) => node.children);
    const flatCategoryTree = flatMapTree.find(
        (node) => node.item.slug === slug
    );
    if (!flatCategoryTree) {
        return tree;
    }
    return flatCategoryTree;
};

const getMainConversationTree = async (
    slug: string,
    organizationName: string,
    user: User
): Promise<Tree<Conversation> | undefined> => {
    const categories = await proClient.getProCategories(organizationName, user);
    const tree = buildTreeFromArray(categories);

    if (!slug) return tree;
    return tree.children.find((node) => node.item.slug === slug);
};

const getConversationTree = async (
    slug: string,
    topLevelSlug: string,
    organizationName: string,
    user: User
): Promise<Tree<Conversation> | undefined> => {
    return isOrganizationCategory(topLevelSlug)
        ? getOrganizationConversationTree(slug, organizationName, user)
        : getMainConversationTree(slug, organizationName, user);
};

const ProBrowseConversations: React.FC = () => {
    const navigate = useNavigate();
    const language: Language = useSelector(languageSelector);
    const user = useSelector(userSelector);
    const { selectProConversation } = useConversationSelector();
    const { isAndroid } = getUAInfo(window.navigator);

    const { pageTitle, newConversationsTitle } = returnCopyForLanguage(
        language,
        copies
    );
    const routes = returnRoutesForLanguage(language, routeObj);

    const scrollPosition = useSelector(scrollPosSelectorProBrowsePage);
    const dispatch = useDispatch();
    const clearScrollPos = (): SetScrollPosActionProBrowsePage =>
        dispatch(setScrollPosProBrowsePage({ x: 0, y: 0 }));

    const navigateToProHomePage = (): void => {
        clearScrollPos();
        navigate(routes.proindex);
    };

    const [isLoading, setIsLoading] = useState(true);
    const [conversationTree, setConversationTree] =
        useState<Tree<Conversation> | undefined>();
    const [conversationArray, setConversationArray] =
        useState<ConversationWithCategory[]>();

    const { categorySlug, topLevelSlug } = useTypedParams<Params>();
    const isNewlyAdded =
        topLevelSlug === 'nyeste_samtaler' ||
        topLevelSlug === 'new_conversations';

    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';

    useEffect(() => {
        if (conversationTree || conversationArray) {
            return;
        }

        async function getUser(): Promise<void> {
            const user = await userClient.getCurrentUser();
            dispatch(setUserState(user!));
        }

        if (user) {
            isNewlyAdded
                ? proClient
                      .getNewlyAddedProConversations()
                      .then(setConversationArray)
                      .finally(() => setIsLoading(false))
                : getConversationTree(
                      categorySlug,
                      topLevelSlug,
                      user.organization,
                      user
                  )
                      .then(setConversationTree)
                      .catch(() => (): void => navigate(routes.error))
                      .finally(() => setIsLoading(false))
                      .catch((err) => (): void => {
                          console.error(err);
                          navigate(routes.error);
                      });
        } else {
            getUser();
        }
    }, [
        categorySlug,
        conversationTree,
        dispatch,
        isMobile,
        navigate,
        routes,
        topLevelSlug,
        user,
    ]);

    useEffect(() => {
        if (scrollPosition)
            window.scrollTo({
                top: scrollPosition.y,
                left: scrollPosition.x,
            });
    }, [conversationTree, scrollPosition]);

    if (isLoading) {
        return <Loading />;
    }

    const isOrganization = isOrganizationCategory(topLevelSlug);
    const needsTopLevelCategory = topLevelSlug !== categorySlug;
    const cardCategory = conversationTree?.item;

    const onConversationSelect = (deck: Conversation): void => {
        dispatch(setScrollPosProBrowsePage(getWindowScrollPosition(window)));
        selectProConversation(
            deck,
            language,
            deckParentPages.proBrowse + `/${topLevelSlug}/${categorySlug}`
        );
    };

    const decorator = new Decorator();

    const cardProps = {
        onClick: onConversationSelect,
        decorator: decorator,
    };

    const title = isNewlyAdded
        ? newConversationsTitle
        : !categorySlug || !conversationTree
        ? ''
        : conversationTree.item.name;

    const isThemeCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'topics':
                return true;
            case 'tema':
                return true;
            default:
                return false;
        }
    };
    const isOrganizationCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'procustomer':
                return true;
            case 'proffkunde':
                return true;
            default:
                return false;
        }
    };

    const conversationCardStyle = (convo: ConversationWithCategory) => {
        let classNamesToReturn = '';
        const conversationIndex = conversationArray?.indexOf(convo);
        let isLeftCard = conversationIndex && conversationIndex % 2 === 0;

        if (isLeftCard === 0) {
            isLeftCard = true;
        }

        classNamesToReturn = classNames(
            css.card,
            css.FavouriteConversationCard,
            isOrganizationCard(convo.taxonomy) && css.OrganizationCard,
            isAndroid && css.AndroidCard,
            isThemeCard(convo.taxonomy) && css.FavouriteThemeCard,
            isLeftCard && isMobile && css.LeftCard,
            !isLeftCard && isMobile && css.RightCard,
            convo.created_by_id &&
                convo.color_option === 1 &&
                css.userCreatedConversation1,
            convo.created_by_id &&
                convo.color_option === 2 &&
                css.userCreatedConversation2,
            convo.created_by_id &&
                convo.color_option === 3 &&
                css.userCreatedConversation3
        );

        return classNamesToReturn;
    };

    return (
        <Layout
            routes={routes}
            language={language}
            pageClassName={css.homePage}
        >
            <SEO title={pageTitle} />
            <article className={css.ContainerWrapper}>
                {/* <section className={css.LogoSection}>{header}</section> */}
                <Container className={css.Container}>
                    <header>
                        <BackButton
                            language={language}
                            className={css.BackButton}
                            clickHandler={navigateToProHomePage}
                        />
                        <h1 className={css.Title}>{title} </h1>
                    </header>
                    {conversationTree && (
                        <ConversationTree
                            tree={conversationTree}
                            cardComponent={
                                isOrganization
                                    ? OrganizationCard
                                    : ConversationCard
                            }
                            cardProps={cardProps}
                            category={
                                needsTopLevelCategory ? cardCategory : undefined
                            }
                        />
                    )}

                    {conversationArray && (
                        <section
                            className={css.ConversationCardsOuterContainer}
                        >
                            <section
                                aria-label={newConversationsTitle}
                                className={css.ConversationCardsContainer}
                            >
                                {conversationArray?.map((convo) =>
                                    isThemeCard(convo.taxonomy) ||
                                    isOrganizationCard(convo.taxonomy) ? (
                                        <ThemeCard
                                            key={convo.id}
                                            conversation={convo}
                                            className={conversationCardStyle(
                                                convo
                                            )}
                                            onClick={(): void =>
                                                onConversationSelect(convo)
                                            }
                                        />
                                    ) : (
                                        <PremiumConversationCard
                                            key={convo.id}
                                            conversation={convo}
                                            className={conversationCardStyle(
                                                convo
                                            )}
                                            onClick={(): void =>
                                                onConversationSelect(convo)
                                            }
                                            category={{
                                                slug: convo.category.slug,
                                                name: convo.category.name,
                                            }}
                                            decorator={decorator}
                                        />
                                    )
                                )}
                            </section>
                        </section>
                    )}
                </Container>
            </article>
        </Layout>
    );
};

export default ProBrowseConversations;
