import Decorator from 'components/conversation-card/decorator';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import classnames from 'classnames';
import conversationClient from 'api/conversation-client';
import styles from './my-library-page.module.scss';
import { Container } from 'components/container';
import { ConversationWithCategory, deckParentPages } from '_types';
import { ConversationCard, ThemeCard } from 'components/conversation-card';
import { Language } from '_types/language';
import {
    scrollPosSelectorMyLibraryPage,
    setScrollPosMyLibraryPage,
} from 'store-modules/app-settings';
import { Layout } from 'components/layout';
import { Loading } from 'components/loading';
import { useNavigate } from 'react-router-dom';
import { RouteList, routesNO, routesEN } from 'api/routes';
import { SEO } from 'components/seo';
import { getWindowScrollPosition } from 'services/get-scroll-pos';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { useDispatch, useSelector } from 'react-redux';
import { getUAInfo } from 'services/user-agent.service';
import {
    favouriteConversationsSelector,
    setFavouriteConversations,
} from 'store-modules/favourite-conversations';
import { setMyConversations } from 'store-modules/my-conversations';
import {
    favouriteQuestionsSelector,
    setFavouriteQuestions,
} from 'store-modules/favourite-questions';
import { myConversationsSelector } from 'store-modules/my-conversations';
import useConversationSelector from 'hooks/use-conversation-selector';
import useTypedLocation from 'hooks/use-typed-location';
import useHistoryRouter from 'hooks/use-history-router';
import collaborationClient, { Collaboration } from 'api/collaboration-client';
import PersonalContentCategoryButtons from './personal-content-category-button-row';

interface LocationState {
    backPath?: string;
}

interface ViewCopy {
    byText: string;
    createConversationLabel: string;
    myConversationsLabel: string;
    seoTitle: string;
}

const nbCopy: ViewCopy = {
    byText: 'av ',
    createConversationLabel: 'Ny samtale',
    myConversationsLabel: 'Mine samtaler',
    seoTitle: 'Mitt bibliotek',
};

const enCopy: ViewCopy = {
    byText: 'by ',
    createConversationLabel: 'New conversation',
    myConversationsLabel: 'My conversations',
    seoTitle: 'My library',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const MyLibraryPage: React.FC = () => {
    const { language } = useHistoryRouter();

    const { byText, createConversationLabel, myConversationsLabel, seoTitle } =
        returnCopyForLanguage(language, copies);
    const location = useTypedLocation<LocationState>();
    const routes = returnRoutesForLanguage(language, routeObj);
    const scrollPosition = useSelector(scrollPosSelectorMyLibraryPage);
    const hash = location.hash.substring(1);
    const { selectConversation } = useConversationSelector();
    const navigate = useNavigate();

    const { isMobile, isAndroid } = getUAInfo(window.navigator);
    const headerProps = {
        withLogin: true,
        withAccount: true,
        withHeaderSpace: false,
    };
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const favouriteConversations = useSelector(favouriteConversationsSelector);
    const favouriteQuestions = useSelector(favouriteQuestionsSelector);
    const myConversations = useSelector(myConversationsSelector);

    const createConversationObject: ConversationWithCategory = {
        category: {
            slug: 'new-conversation',
            name: ' ',
        },
        id: 0,
        name: createConversationLabel,
        count: 0,
        parent: 0,
        slug: 'new-conversation',
        description: '',
        taxonomy: 'new-conversation',
    };

    const conversationsToDisplay: ConversationWithCategory[] = [];

    const myConversationsToDisplay = myConversations
        ?.filter((c) => c != undefined && c.id != null)
        .map((c) => {
            return {
                ...c,
                category: { ...c.category, name: byText + c.category.name },
            };
        });

    const convos =
        myConversationsToDisplay && myConversationsToDisplay?.length > 0
            ? conversationsToDisplay.concat(
                  [createConversationObject],
                  myConversationsToDisplay!
              )
            : [createConversationObject];

    const hasFavouriteQuestions =
        favouriteQuestions && favouriteQuestions.length > 0;
    const hasFavouriteConversations =
        !!favouriteConversations && favouriteConversations.length > 0;

    const decorator = new Decorator();

    const [collaborations, setCollaborations] = useState<Collaboration[]>();
    const [loadingCollaborations, setLoadingCollaborations] = useState(true);

    //Get my collaborations
    useEffect(() => {
        collaborationClient
            .myCollaborations()
            .then((response) => setCollaborations(response))
            .catch(console.error)
            .finally(() => setLoadingCollaborations(false));
    }, []);

    //Get my favourites
    useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchFavourites(): Promise<void> {
            if (hash && hash.length) {
                const anchorElement = document.getElementById(hash);
                if (anchorElement) anchorElement.scrollIntoView();
            }

            Promise.all([
                conversationClient.getMyFavouriteConversations(),
                conversationClient.getMyFavouriteQuestions(),
            ])
                .then((response) => {
                    dispatch(setFavouriteConversations(response[0].reverse()));
                    dispatch(setFavouriteQuestions(response[1].reverse()));

                    if (response.length > 0 && myConversations) {
                        setIsLoading(false);
                    }
                })
                .catch(console.error);
        }
        if (!favouriteQuestions || !favouriteConversations) {
            fetchFavourites();
        } else {
            setIsLoading(false);
        }
        if (scrollPosition) {
            //timeout hack to grab correct element
            setTimeout(function () {
                window.scrollTo({
                    top: scrollPosition.y,
                    left: scrollPosition.x,
                });
            }, 2);
        }
    }, [
        hash,
        dispatch,
        favouriteQuestions,
        favouriteConversations,
        scrollPosition,
    ]);

    //Get my conversations
    useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchMyConversations(): Promise<void> {
            Promise.all([conversationClient.getMyConversations()]).then(
                (response) => {
                    dispatch(setMyConversations(response[0].reverse()));

                    if (
                        response.length > 0 &&
                        favouriteConversations &&
                        favouriteQuestions
                    ) {
                        setIsLoading(false);
                    }
                }
            );
        }

        if (!myConversations) {
            fetchMyConversations();
        } else {
            setIsLoading(false);
        }
        if (scrollPosition) {
            //timeout hack to grab correct element
            setTimeout(function () {
                window.scrollTo({
                    top: scrollPosition.y,
                    left: scrollPosition.x,
                });
            }, 2);
        }
    }, [hash, dispatch, myConversations, scrollPosition]);

    if (isLoading || loadingCollaborations) {
        return <Loading />;
    }

    const onConversationSelect = (deck: ConversationWithCategory): void => {
        dispatch(setScrollPosMyLibraryPage(getWindowScrollPosition(window)));
        if (deck.id === 0) {
            navigate(routes.createConversation);
        } else {
            selectConversation(deck, language, deckParentPages.myLibrary);
        }
    };

    const pageClassName =
        hasFavouriteQuestions && !hasFavouriteConversations && isMobile
            ? classnames(
                  styles.MyLibraryPageContainerQuestionsOnlyMobile,
                  styles.ColorBg
              )
            : classnames(
                  styles.MyLibraryPageContainer,
                  styles.ColorBg,
                  isAndroid && styles.Android
              );

    const isThemeCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'topics':
                return true;
            case 'tema':
                return true;
            default:
                return false;
        }
    };
    const isOrganizationCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'procustomer':
                return true;
            case 'proffkunde':
                return true;
            default:
                return false;
        }
    };

    const conversationCardStyle = (convo: ConversationWithCategory) => {
        let classNamesToReturn = '';
        const conversationIndex = convos?.indexOf(convo);
        let isLeftCard = conversationIndex && conversationIndex % 2 === 0;

        if (isLeftCard === 0) {
            isLeftCard = true;
        }

        classNamesToReturn = classNames(
            styles.FavouriteConversationCard,
            isOrganizationCard(convo.taxonomy) && styles.OrganizationCard,
            isAndroid && styles.AndroidCard,
            isThemeCard(convo.taxonomy) && styles.FavouriteThemeCard,
            isLeftCard && isMobile && styles.LeftCard,
            !isLeftCard && isMobile && styles.RightCard,
            convo.created_by_id &&
                convo.color_option === 1 &&
                styles.userCreatedConversation1,
            convo.created_by_id &&
                convo.color_option === 2 &&
                styles.userCreatedConversation2,
            convo.created_by_id &&
                convo.color_option === 3 &&
                styles.userCreatedConversation3
        );

        return classNamesToReturn;
    };

    return (
        <Layout
            routes={routes}
            language={language}
            headerProps={headerProps}
            withHeaderSpace={headerProps.withHeaderSpace}
            pageClassName={pageClassName}
        >
            <SEO title={seoTitle} />
            <Container className={styles.container}>
                <h2 className={styles.MyFavouritesTitle}>{seoTitle}</h2>
            </Container>

            <section className={styles.libraryContainer}>
                <section className={styles.ResultContainer}>
                    <PersonalContentCategoryButtons
                        collaborations={collaborations}
                    />

                    <Container className={styles.ConversationsContainer}>
                        <header className={styles.header}>
                            <h2
                                id='conversations-row-title'
                                className={styles.FavouriteConversationsTitle}
                            >
                                {myConversationsLabel}
                            </h2>
                        </header>
                        <section
                            className={styles.ConversationCardsOuterContainer}
                        >
                            <section
                                aria-label={myConversationsLabel}
                                className={styles.ConversationCardsContainer}
                            >
                                {convos.map((convo) =>
                                    isThemeCard(convo.taxonomy) ||
                                    isOrganizationCard(convo.taxonomy) ? (
                                        <ThemeCard
                                            key={convo.id}
                                            conversation={convo}
                                            className={conversationCardStyle(
                                                convo
                                            )}
                                            onClick={(): void =>
                                                onConversationSelect(convo)
                                            }
                                        />
                                    ) : (
                                        <ConversationCard
                                            key={convo.id}
                                            conversation={convo}
                                            className={conversationCardStyle(
                                                convo
                                            )}
                                            onClick={(): void =>
                                                onConversationSelect(convo)
                                            }
                                            category={{
                                                slug: convo.category.slug,
                                                name: convo.category.name,
                                            }}
                                            decorator={decorator}
                                        />
                                    )
                                )}
                            </section>
                        </section>
                    </Container>
                </section>
            </section>
        </Layout>
    );
};

export default MyLibraryPage;
