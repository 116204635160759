import React, { useState, useEffect } from 'react';
import styles from './browse-conversations-page.module.scss';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    Conversation,
    ConversationWithCategory,
    deckParentPages,
} from '_types';
import { Container } from 'components/container';
import { Tree, buildTreeFromArray } from 'utils/tree-utils';
import { Layout } from 'components/layout';
import conversationClient from 'api/conversation-client';
import ConversationTree from './conversation-tree';
import { SEO } from 'components/seo';
import { THEMES_CATEGORY } from 'services/conversation-service';
import { Loading } from 'components/loading';
import { ConversationCard, ThemeCard } from 'components/conversation-card';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { BackButton } from 'components/back-button';
import { Language } from '_types/language';
import {
    returnRoutesForLanguage,
    returnCopyForLanguage,
} from 'services/language-service';
import { getWindowScrollPosition } from 'services/get-scroll-pos';
import {
    scrollPosSelectorPremiumBrowsePage,
    setScrollPosPremiumBrowsePage,
    SetScrollPosActionPremiumBrowsePage,
    languageSelector,
} from 'store-modules/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'common-logic/use-mounted-state';
import { useWindowSize } from 'hooks';
import Decorator from 'components/conversation-card/decorator';
import useConversationSelector from 'hooks/use-conversation-selector';
import useTypedParams from 'hooks/use-typed-params';
import classNames from 'classnames';
import { getUAInfo } from 'services/user-agent.service';
import classnames from 'classnames';

interface Params {
    categorySlug: string;
}
interface ViewCopy {
    themesTitle: string;
    newConversationsTitle: string;
}

const nbCopy: ViewCopy = {
    themesTitle: 'Tema',
    newConversationsTitle: 'Nyeste samtaler',
};

const enCopy: ViewCopy = {
    themesTitle: 'Timely topics',
    newConversationsTitle: 'New conversations',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const isThemesCategory = (categorySlug: string): boolean =>
    categorySlug === 'tema';

// FIXME: BE endpoint for specific category is in progress, update/move/refactor when it's done
const getConversationTree = (categorySlug: string) =>
    isThemesCategory(categorySlug)
        ? conversationClient.getThemes().then(buildTreeFromArray)
        : conversationClient
              .getConversations()
              .then(buildTreeFromArray)
              .then((tree) =>
                  tree.children.find((node) => node.item.slug === categorySlug)
              );

const BrowseConversationsPage: React.FC = () => {
    const navigate = useNavigate();
    const isMounted = useMountedState();
    const language: Language = useSelector(languageSelector);
    const { themesTitle, newConversationsTitle } = returnCopyForLanguage(
        language,
        copies
    );
    const routes = returnRoutesForLanguage(language, routeObj);
    const scrollPosition = useSelector(scrollPosSelectorPremiumBrowsePage);
    const dispatch = useDispatch();
    const clearScrollPos = (): SetScrollPosActionPremiumBrowsePage =>
        dispatch(setScrollPosPremiumBrowsePage({ x: 0, y: 0 }));
    const { selectConversation } = useConversationSelector();
    const { isAndroid } = getUAInfo(window.navigator);

    const navigateToHomePage = (): void => {
        clearScrollPos();
        navigate(routes.index);
    };
    const [isLoading, setIsLoading] = useState(true);
    const [conversationTree, setConversationTree] =
        useState<Tree<Conversation> | undefined>();
    const [conversationArray, setConversationArray] =
        useState<ConversationWithCategory[]>();
    const { categorySlug } = useTypedParams<Params>();
    const isNewlyAdded =
        categorySlug === 'nyeste_samtaler' ||
        categorySlug === 'new_conversations';

    const { view } = useWindowSize(window);
    const isMobile = view === 'mobile';

    useEffect(() => {
        if (conversationTree || conversationArray) {
            return;
        }
        isNewlyAdded
            ? conversationClient
                  .getNewlyAddedConversations()
                  .then(setConversationArray)
                  .finally(() => setIsLoading(false))
            : getConversationTree(categorySlug)
                  .then((tree) => {
                      if (!isMounted()) return;

                      setConversationTree(tree);
                  })
                  .catch(() => navigate(routes.error))
                  .finally(() => setIsLoading(false));
    }, [
        categorySlug,
        conversationTree,
        dispatch,
        isMobile,
        isMounted,
        navigate,
        routes,
    ]);

    useEffect(() => {
        if (scrollPosition)
            window.scrollTo({
                top: scrollPosition.y,
                left: scrollPosition.x,
            });
    }, [conversationTree, scrollPosition]);

    if (isLoading) {
        return <Loading />;
    }

    // FIXME: redirect to 404?
    if (!conversationTree && !conversationArray) {
        return <Navigate to={routes.index} replace />;
    }

    const isThemes = isThemesCategory(categorySlug);
    const title = isNewlyAdded
        ? newConversationsTitle
        : isThemes
        ? themesTitle
        : conversationTree?.item.name;
    const onConversationSelect = (deck: Conversation): void => {
        dispatch(
            setScrollPosPremiumBrowsePage(getWindowScrollPosition(window))
        );
        selectConversation(
            deck,
            language,
            deckParentPages.premiumBrowse + '/' + categorySlug
        );
    };

    const cardProps = {
        className: styles.card,
        category: isThemes
            ? THEMES_CATEGORY
            : {
                  name: conversationTree
                      ? conversationTree.item.name
                      : 'newly_added',
                  slug: conversationTree
                      ? conversationTree?.item.slug
                      : 'newly_added',
              },
    };

    const decorator = new Decorator();

    const createCard = isThemes
        ? (conversation: Conversation) => (
              <ThemeCard
                  key={conversation.id}
                  conversation={conversation}
                  onClick={(): void => onConversationSelect(conversation)}
                  {...cardProps}
              />
          )
        : (conversation: Conversation) => (
              <ConversationCard
                  key={conversation.id}
                  conversation={conversation}
                  onClick={(): void => onConversationSelect(conversation)}
                  decorator={decorator}
                  {...cardProps}
              />
          );

    const isThemeCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'topics':
                return true;
            case 'tema':
                return true;
            default:
                return false;
        }
    };
    const isOrganizationCard = (taxonomy: string) => {
        switch (taxonomy.toLocaleLowerCase()) {
            case 'procustomer':
                return true;
            case 'proffkunde':
                return true;
            default:
                return false;
        }
    };

    const conversationCardStyle = (convo: ConversationWithCategory) => {
        let classNamesToReturn = '';
        const conversationIndex = conversationArray?.indexOf(convo);
        let isLeftCard = conversationIndex && conversationIndex % 2 === 0;

        if (isLeftCard === 0) {
            isLeftCard = true;
        }

        classNamesToReturn = classNames(
            styles.card,
            styles.FavouriteConversationCard,
            isOrganizationCard(convo.taxonomy) && styles.OrganizationCard,
            isAndroid && styles.AndroidCard,
            isThemeCard(convo.taxonomy) && styles.FavouriteThemeCard,
            isLeftCard && isMobile && styles.LeftCard,
            !isLeftCard && isMobile && styles.RightCard,
            convo.created_by_id &&
                convo.color_option === 1 &&
                styles.userCreatedConversation1,
            convo.created_by_id &&
                convo.color_option === 2 &&
                styles.userCreatedConversation2,
            convo.created_by_id &&
                convo.color_option === 3 &&
                styles.userCreatedConversation3
        );

        return classNamesToReturn;
    };

    return (
        <Layout routes={routes} language={language}>
            <SEO title={title} />
            <Container className={styles.container}>
                <header>
                    <BackButton
                        language={language}
                        className={styles.backButton}
                        clickHandler={navigateToHomePage}
                    />
                    <h1 className={styles.title}>{title}</h1>
                </header>

                {conversationTree && (
                    <ConversationTree
                        tree={conversationTree}
                        createCard={createCard}
                    />
                )}

                {conversationArray && (
                    <section className={styles.ConversationCardsOuterContainer}>
                        <section
                            aria-label={newConversationsTitle}
                            className={classnames(
                                styles.ConversationCardsContainer,
                                isNewlyAdded &&
                                    styles.newlyAddedConversationsSection
                            )}
                        >
                            {conversationArray?.map((convo) =>
                                isThemeCard(convo.taxonomy) ||
                                isOrganizationCard(convo.taxonomy) ? (
                                    <ThemeCard
                                        key={convo.id}
                                        conversation={convo}
                                        className={conversationCardStyle(convo)}
                                        onClick={(): void =>
                                            onConversationSelect(convo)
                                        }
                                    />
                                ) : (
                                    <ConversationCard
                                        key={convo.id}
                                        conversation={convo}
                                        className={conversationCardStyle(convo)}
                                        onClick={(): void =>
                                            onConversationSelect(convo)
                                        }
                                        category={{
                                            slug: convo.category.slug,
                                            name: convo.category.name,
                                        }}
                                        decorator={decorator}
                                    />
                                )
                            )}
                        </section>
                    </section>
                )}
            </Container>
        </Layout>
    );
};

export default BrowseConversationsPage;
