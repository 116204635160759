import React, { useRef, useEffect, useState } from 'react';
import { Steps } from '../../../components/steps';
import { Link } from '../../../components/link';
import { BamboraInlineCheckout } from './bambora-inline-checkout';
import styles from './payment-option-select.module.scss';

import { PageTitle } from 'components/page-title';
import { Language } from '_types/language';
import { useSelector } from 'react-redux';
import { deviceSelector, languageSelector } from 'store-modules/app-settings';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import DiscountCode from './discount-code';
import { Discount } from '../_types';
import { StripeProvider } from './stripe';
import { InAppPurchase } from './in-app-purchase/in-app-purchase';

import { SessionResponse } from 'pages/create-premium-page/_services';
import { Product } from 'pages/create-premium-page/_types';
import { routeObj } from 'pages/account-page/account-home/account-home';
import { Dots } from 'components/loading/dots';
import { primaryKeyPressed } from 'utils/keyboard-utils';
import { ReactComponent as IconVisa } from '../../../images/icons/betaling-visa.svg';
import { ReactComponent as IconMasterCard } from '../../../images/icons/betaling-mastercard.svg';
import { ReactComponent as IconDk } from '../../../images/icons/betaling-dk.svg';
import { getUAInfo } from 'services/user-agent.service';
import { IS_MOBILE_APP, IS_ANDROID_APP } from './../../../constants';
import subscriptionClient from 'api/subscription-client';

type Props = {
    selectedProduct: Product | undefined;
    session: SessionResponse;
    discount: Discount | undefined;
    discountCode: string | undefined;
    onSuccess: () => void;
    onCancel: () => void;
    onCancelProcess: () => void;
    onCodeSelected: (text: string) => void;
    onCodeCleared: () => void;
    createOrderForBambora: () => void;
};
interface ViewCopy {
    title: string;
    cancelProcessBtn: string;
    changePlan: string;
    linkPrivacy: string;
    linkTnc: string;
    payWithBamboraButton: string;
}

const nbCopy: ViewCopy = {
    title: 'Fullfør betaling',
    cancelProcessBtn: 'Avbryt betaling',
    changePlan: 'Endre plan',
    linkPrivacy: 'Personvern',
    linkTnc: 'Vilkår for bruk',
    payWithBamboraButton: 'Betalingskort',
};

const enCopy: ViewCopy = {
    title: 'Complete payment',
    cancelProcessBtn: 'Cancel payment',
    changePlan: 'Change plan',
    linkPrivacy: 'Privacy policy',
    linkTnc: 'Terms of use',
    payWithBamboraButton: 'Credit Card',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const PaymentOptionSelect: React.FC<Props> = ({
    selectedProduct,
    session,
    discount,
    discountCode,
    onSuccess,
    onCancel,
    onCancelProcess,
    onCodeSelected,
    onCodeCleared,
    createOrderForBambora,
}: Props) => {
    const language: Language = useSelector(languageSelector);
    const device = useSelector(deviceSelector);
    const ref = useRef<HTMLDivElement>(null);
    const [payWithBamboraButtonClicked, setPayWithBamboraButtonClicked] =
        useState<boolean>(false);
    const { isiOSWeb } = getUAInfo(window.navigator);
    const [paymentComplete, setPaymentComplete] = useState(false);

    const beforeOnSuccess = () => {
        setPaymentComplete(true);
        onSuccess();
    };

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    //This checks every 10 seconds if the user has already paid on another device, the user will get redirected to "Welcome"
    useEffect(() => {
        const interval = setInterval(() => {
            if (!paymentComplete)
                subscriptionClient.getActiveSubscription().then((sub) => {
                    const next_payment_date = sub?.data?.subscription
                        .next_payment_date
                        ? new Date(sub?.data?.subscription.next_payment_date)
                        : null;
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    if (next_payment_date) {
                        if (next_payment_date > today) {
                            beforeOnSuccess();
                        }
                    }
                });
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const routes = returnRoutesForLanguage(language, routeObj);

    const {
        title,
        cancelProcessBtn,
        changePlan,
        linkPrivacy,
        linkTnc,
        payWithBamboraButton,
    } = returnCopyForLanguage(language, copies);

    const onPayWithBamboraButtonClicked = () => {
        setPayWithBamboraButtonClicked(true);
        createOrderForBambora();
    };

    const planSummary = selectedProduct ? (
        <section className={styles.PlanSummary} aria-label='plan summary'>
            <div className={styles.PlanSummary__Content}>
                <b>{selectedProduct.name}</b>
                <span>•</span>

                {discount?.discountedPrice ? (
                    <s>
                        {selectedProduct.price}&nbsp;
                        {selectedProduct.currency}
                    </s>
                ) : (
                    <>
                        {selectedProduct.price}&nbsp;
                        {selectedProduct.currency}
                    </>
                )}
                <span>•</span>
                <button
                    type='button'
                    className={styles.ButtonLink}
                    onClick={onCancel}
                >
                    {changePlan}
                </button>
            </div>
        </section>
    ) : null;

    return (
        <article
            data-testid='component-payment-select'
            className={styles.PaymentOptionSelect}
        >
            <section className={styles.Steps}>
                <Steps current={3} total={3} language={language} />
            </section>
            <PageTitle title={title} />
            {planSummary}
            <section className={styles.Content}>
                <section
                    className={styles.CCForm}
                    aria-labelledby='form'
                    data-testid='section-form'
                >
                    {!IS_MOBILE_APP && (
                        <DiscountCode
                            language={language}
                            onCodeSelected={onCodeSelected}
                            onCodeCleared={onCodeCleared}
                            discount={discount}
                            currency={selectedProduct?.currency}
                        />
                    )}

                    {IS_MOBILE_APP && selectedProduct && (
                        <InAppPurchase
                            discountCode={discountCode}
                            productID={selectedProduct?.id}
                            productPrice={selectedProduct?.price}
                            onSuccess={beforeOnSuccess}
                            onCancel={onCancel}
                        />
                    )}

                    {device !== 'ios' && isiOSWeb && selectedProduct && (
                        <StripeProvider
                            price={selectedProduct?.price}
                            currency={selectedProduct?.currency}
                            discountCode={discountCode}
                            productID={selectedProduct?.id}
                            onSuccess={beforeOnSuccess}
                            onCancel={onCancel}
                        />
                    )}

                    {device !== 'ios' &&
                        !IS_ANDROID_APP &&
                        !session.token &&
                        !payWithBamboraButtonClicked && (
                            // Display button to start bambora payment
                            <div
                                tabIndex={0}
                                role='button'
                                onKeyDown={(e) => {
                                    if (primaryKeyPressed(e)) {
                                        onPayWithBamboraButtonClicked();
                                    }
                                }}
                                className={styles.PayWithBamboraButton}
                                onClick={onPayWithBamboraButtonClicked}
                            >
                                <div
                                    className={styles.PayWithBamboraButtonLabel}
                                >
                                    {payWithBamboraButton}
                                </div>
                                <div className={styles.CardOptions}>
                                    <IconVisa
                                        className={styles.CreditCardIcon}
                                    />
                                    <IconMasterCard
                                        className={styles.CreditCardIcon}
                                    />
                                    <IconDk className={styles.CreditCardIcon} />
                                </div>
                            </div>
                        )}
                    {device !== 'ios' &&
                        (!session.token || session.token.length < 1) &&
                        payWithBamboraButtonClicked && (
                            <section className={styles.dots}>
                                <div>
                                    <Dots />
                                </div>
                            </section>
                        )}

                    {device !== 'ios' &&
                        session.token &&
                        payWithBamboraButtonClicked && (
                            //Display bambora payment
                            <div className={styles.Form__Bambora}>
                                <BamboraInlineCheckout
                                    sessionToken={session.token}
                                    successHandler={beforeOnSuccess}
                                    cancelHandler={onCancel}
                                />
                            </div>
                        )}
                    <div className={styles.CancelProcess}>
                        <button
                            type='button'
                            className={styles.ButtonLink}
                            onClick={onCancelProcess}
                        >
                            {cancelProcessBtn}
                        </button>
                    </div>
                </section>
                <section className={styles.links} aria-label='links'>
                    <Link className={styles.link} to={routes.privacyTerms}>
                        {linkPrivacy}
                    </Link>
                    <Link className={styles.link} to={routes.tnc}>
                        {linkTnc}
                    </Link>
                </section>
            </section>

            <div ref={ref} />
        </article>
    );
};

export default React.memo(PaymentOptionSelect);
