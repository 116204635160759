import React, { useEffect } from 'react';
import { AppRouter } from 'app-router';
import './app.module.scss';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/mousewheel';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

import { SplashScreen } from '@capacitor/splash-screen';
import { IS_ANDROID_APP, IS_MOBILE_APP, IS_IOS_APP } from './constants';
import { useDispatch } from 'react-redux';
import { detectDevice } from 'store-modules/app-settings';
import { initializeLanguage } from 'services/language-service';
import {
    InAppPurchase2 as IAP2,
    IAPProduct,
} from '@ionic-native/in-app-purchase-2';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import firebaseConfig from 'utils/firebase';
import './i18n';

//native analytics
//not necessay on android at least
if (!IS_ANDROID_APP) {
    FirebaseAnalytics.initializeFirebase(firebaseConfig).then(() => {
        FirebaseAnalytics.setCollectionEnabled({
            enabled: true,
        });
        FirebaseAnalytics.enable();
    });
}

const PRODUCTS = new Map();

if (IS_MOBILE_APP) {
    PRODUCTS.set(299, {
        id: IS_ANDROID_APP
            ? 'annual_subscription_android'
            : 'Annual_subscription',
        type: IAP2.PAID_SUBSCRIPTION,
    });

    PRODUCTS.set(49, {
        id: IS_ANDROID_APP
            ? 'monthly_subscription_android'
            : 'Monthly_subscription',
        type: IAP2.PAID_SUBSCRIPTION,
    });

    if (IS_IOS_APP) {
        PRODUCTS.set(209, {
            id: 'Annual_company_subscription',
            type: IAP2.PAID_SUBSCRIPTION,
        });
        PRODUCTS.set(179, {
            id: 'Annual_business_subscription',
            type: IAP2.PAID_SUBSCRIPTION,
        });
        PRODUCTS.set(149, {
            id: 'Annual_corporate_subscription',
            type: IAP2.PAID_SUBSCRIPTION,
        });
        PRODUCTS.set(35, {
            id: 'Monthly_company_subscription',
            type: IAP2.PAID_SUBSCRIPTION,
        });
        PRODUCTS.set(25, {
            id: 'Monthly_corporate_subscription',
            type: IAP2.PAID_SUBSCRIPTION,
        });
    }

    if (IAP2) {
        PRODUCTS.forEach((i) => {
            const id = i.id;
            const type = i.type;
            IAP2.register({ type, id });
            // IAP2.when(i.id).updated(() => {
            //     const p = IAP2.get(i.id);
            //     setProduct(p);
            //   });
            IAP2.when(i.id).approved(function (p: IAPProduct) {
                // console.log('approved', i);
                p.verify();
            });
            IAP2.when(i.id).verified(function (p: IAPProduct) {
                // console.log('verified');
                p.finish();
            });
            IAP2.when(i.id).finished(() => {
                // console.log('finished');
            });
            IAP2.when(i.id).cancelled(() => {
                console.error('Purchase was Cancelled');
            });
            IAP2.when(i.id).unverified((p: IAPProduct) => {
                // console.log('unverified');

                console.error(p);
            });

            IAP2.error((err: Error) => {
                console.error(err);
            });
        });
    }
}
export { PRODUCTS };

// Annual subscription
// Årlig abonnement
// NOK 299
// ID: Annual_Payment

// Annual company subscription
// Årlig bedriftsabonnement
// 30%
// NOK 209
// ID: Annual_company_subscription

// Annual business subscription
// Årlig forretningsabonnement
// 40%
// NOK 179
// ID: Annual_business_subscription

// Annual corporate subscription
// Årlig bedriftsabonnement
// 50%
// NOK 149
// ID: Annual_corporate_subscription

// Monthly subscription
// Månedlig abonnement
// NOK 49
// ID: Monthly_Payment

// Monthly company subscription
// Månedlig firmabonnement
// 30%
// NOK 35
// ID: Monthly_company_subscription

// Monthly corporate subscription
// Månedlig bedriftsabonnement
// 50%
// NOK 25
// ID: Monthly_corporate_subscription

const App: React.FC = () => {
    const dispatch = useDispatch();

    if (IAP2) {
        IAP2.refresh();
    }
    useEffect(() => {
        initializeLanguage(dispatch);

        if (IS_MOBILE_APP) {
            dispatch(detectDevice(IS_ANDROID_APP ? 'android' : 'ios'));
            if (IS_IOS_APP) {
                SplashScreen.hide().then();
            }
        }
    }, [dispatch]);

    return <AppRouter />;
};

export default App;
