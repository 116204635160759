import React, { useState } from 'react';
import { routesNO, RouteList, routesEN } from 'api/routes';
import { Button } from 'components/base';
import { GoogleLoginButton } from 'components/google-login-button';
import { Container } from 'components/container';
import { FormError } from 'components/forms';
import { FreemiumNavbar } from 'components/freemium-navbar';
import { Layout } from 'components/layout';
import { Link } from 'components/link';
import { PageTitle } from 'components/page-title';
import { SEO } from 'components/seo';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { languageSelector } from 'store-modules/app-settings';
import styles from './login-page.module.scss';
import { Language } from '_types/language';
import {
    returnRoutesForLanguage,
    returnCopyForLanguage,
} from 'services/language-service';
import subscriptionClient from 'api/subscription-client';
import { setProAccount } from 'store-modules/professional';
import { logUserIn, LoginStatus } from 'common-logic/login';
import { Loading } from 'components/loading';
import { AppleLoginButton } from 'components/apple-login-button';
import { AzureLoginButton } from 'components/azure-login-button';
import { getUAInfo } from 'services/user-agent.service';
import { IS_ANDROID_APP } from '../../constants';
import AzureAuthenticationContext from 'components/azure-login-button/azure-authentication-context';
import { useNavigate } from 'react-router-dom';
import useTypedLocation from 'hooks/use-typed-location';
import useHistoryRouter from 'hooks/use-history-router';
import { RFF_PasswordField, RFF_TextField, Stack } from 'components/base';
import { useTranslation } from 'react-i18next';
import { setUserState, setUserSub } from 'store-modules/user';
import { Subscription, User } from '_types';

interface RouteState {
    redirectedFromConfirmation?: boolean;
}

interface ViewCopy {
    appleLoginTitle: string;
    azureInfoText: string;
    azureLoginTitle: string;
    buttonSubmit: string;
    emailErrorLabel: string;
    divider: string;
    googleLoginTitle: string;
    linkPasswordReset: string;
    linkRegister: string;
    missingFieldsError: string;
    pageTitle: string;
    passwordErrorLabel: string;
    placeholderPassword: string;
    subtitleText: string;
    textRegister: string;
    titleConfirmed: string;
    titleDefault: string;
}

const nbCopy: ViewCopy = {
    appleLoginTitle: 'Logg inn med Apple',
    azureInfoText:
        '* Kun tilgjengelig for FuelBox Proff brukere med Microsoft-konto',
    azureLoginTitle: 'Logg inn med Microsoft *',
    buttonSubmit: 'Logg inn',
    emailErrorLabel: 'Vi kjenner ikke igjen denne epostadressen',
    divider: 'Eller',
    googleLoginTitle: 'Logg inn med Google',
    linkPasswordReset: 'Glemt passordet ditt?',
    linkRegister: 'Opprett bruker',
    missingFieldsError: 'Har du husket å fylle ut begge feltene?',
    pageTitle: 'Logg inn',
    passwordErrorLabel: 'Feil passord',
    placeholderPassword: 'Passord',
    subtitleText: 'Logg inn for å betale',
    textRegister: 'Ikke registrert? ',
    titleConfirmed: 'Takk!',
    titleDefault: 'Logg inn',
};

const enCopy: ViewCopy = {
    appleLoginTitle: 'Log in with Apple',
    azureInfoText:
        '* Only available for FuelBox Pro users with a Microsoft account',
    azureLoginTitle: 'Log in with Microsoft *',
    buttonSubmit: 'Log in',
    emailErrorLabel: "We don't recognise this email address",
    divider: 'Or',
    googleLoginTitle: 'Log in with Google',
    linkPasswordReset: 'Forgotten your password?',
    linkRegister: 'Create an account',
    missingFieldsError: 'Have you entered email and password?',
    pageTitle: '',
    passwordErrorLabel: 'Wrong password',
    placeholderPassword: 'Password',
    subtitleText: 'Log in to pay',
    textRegister: 'Not yet a user? ',
    titleConfirmed: 'Thanks!',
    titleDefault: 'Log in',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];

const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

interface LoginForm {
    username: string;
    password: string;
}

const isEmpty = (value: string | undefined | null): boolean => !value;

const LoginPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const language: Language = useSelector(languageSelector);
    const { isiOS, isiOSWeb } = getUAInfo(window.navigator);
    const authenticationModule: AzureAuthenticationContext =
        new AzureAuthenticationContext();
    const { setRedirectToPayment } = useHistoryRouter();

    const {
        appleLoginTitle,
        azureInfoText,
        azureLoginTitle,
        buttonSubmit,
        emailErrorLabel,
        divider,
        googleLoginTitle,
        linkPasswordReset,
        linkRegister,
        missingFieldsError,
        pageTitle,
        passwordErrorLabel,
        textRegister,
        titleConfirmed,
        titleDefault,
    } = returnCopyForLanguage(language, copies);
    const routes = returnRoutesForLanguage(language, routeObj);
    const emailError = {
        username: true,
        password: false,
        [FORM_ERROR]: emailErrorLabel,
    };

    const passwordError = {
        username: false,
        password: true,
        [FORM_ERROR]: passwordErrorLabel,
    };
    const location = useTypedLocation<RouteState>();

    const redirectedFromConfirmation =
        !!location.state && location.state.redirectedFromConfirmation;
    const title = redirectedFromConfirmation ? titleConfirmed : titleDefault;

    const [loading, setLoading] = useState<boolean>(false);

    const routeAfterLogin = (sub: Subscription, user: User | null) => {
        const stillActiveStatuses = ['active', 'pending-cancel'];
        const status = sub.data.status;
        const stillActive = stillActiveStatuses.includes(status);
        const isProUser = !!user?.organization;
        const proContentDisabled = sub.data.pro_content_disabled;

        let route = routes.index;

        if (!stillActive) {
            route = routes.account.index;
        }
        if (isProUser) {
            route = routes.proindex;
            if (proContentDisabled) {
                route = routes.index;
            }
            if (!user?.isConfirmed) {
                route = routes.createAccountConfirmation;
            }
        }

        navigate(route);
    };

    const formSubmit = async (values: LoginForm) => {
        const isEmptyUsername = isEmpty(values.username);
        const isEmptyPassword = isEmpty(values.password);

        if (isEmptyUsername || isEmptyPassword) {
            return {
                username: isEmptyUsername,
                password: isEmptyPassword,
                [FORM_ERROR]: missingFieldsError,
            };
        }

        try {
            const loginResult = await logUserIn(
                values.username,
                values.password,
                (organization) => dispatch(setProAccount(organization))
            );

            loginResult.subscription &&
                dispatch(setUserSub(loginResult.subscription));
            loginResult.user && dispatch(setUserState(loginResult.user));

            switch (loginResult.loginStatus) {
                case LoginStatus.InvalidUsername:
                    return emailError;
                case LoginStatus.InvalidPassword:
                    return passwordError;
                case LoginStatus.Error:
                    navigate(routes.error);
                    return;
            }

            if (redirectedFromConfirmation) setRedirectToPayment(true);
            if (loginResult.subscription) {
                routeAfterLogin(loginResult.subscription, loginResult.user);
            } else {
                subscriptionClient
                    .getActiveSubscription()
                    .then((sub) => {
                        if (!!sub) {
                            routeAfterLogin(sub, loginResult.user);
                        }
                    })
                    .catch(console.error);
            }
        } catch {
            navigate(routes.error);
        }
    };

    const titleUI = <PageTitle title={title} />;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Layout
                    routes={routes}
                    language={language}
                    pageClassName={styles.loginPage}
                    headerProps={{
                        customNavigation: FreemiumNavbar,
                        showLogoLink: true,
                    }}
                >
                    <SEO
                        title={pageTitle}
                        noIndex={redirectedFromConfirmation}
                    />
                    <Container className={styles.container}>
                        {titleUI}
                        {!redirectedFromConfirmation && (
                            <p className={styles.register}>
                                {textRegister}
                                <Link to={routes.createPremium}>
                                    {linkRegister}
                                </Link>
                            </p>
                        )}
                        <Form
                            onSubmit={formSubmit}
                            render={({
                                handleSubmit,
                                submitError,
                                submitting,
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className={styles.form}
                                >
                                    <Stack spacing={3} mb={2.5}>
                                        <RFF_TextField
                                            inputProps={{
                                                'aria-label': t(
                                                    'form.username.label'
                                                ),
                                            }}
                                            name='username'
                                            placeholder={t(
                                                'form.username.placeholder'
                                            )}
                                            rounded
                                            startIcon='person'
                                        />

                                        <RFF_PasswordField
                                            inputProps={{
                                                'aria-label': t(
                                                    'form.password.label'
                                                ),
                                            }}
                                            name='password'
                                            placeholder={t(
                                                'form.password.placeholder'
                                            )}
                                            rounded
                                        />
                                    </Stack>

                                    <p className={styles.reset}>
                                        <Link to={routes.passReset}>
                                            {linkPasswordReset}
                                        </Link>
                                    </p>

                                    <FormError message={submitError} />
                                    <div className={styles.button}>
                                        <Button
                                            type='submit'
                                            appearance='cta'
                                            fullWidth={true}
                                            loading={submitting}
                                            disabled={submitting}
                                        >
                                            {buttonSubmit}
                                        </Button>
                                    </div>
                                    {!IS_ANDROID_APP && (
                                        <div className={styles.LoginDivider}>
                                            <hr className={styles.LeftLine} />
                                            <p
                                                className={
                                                    styles.LoginDividerText
                                                }
                                            >
                                                {divider}
                                            </p>
                                            <hr className={styles.RightLine} />
                                        </div>
                                    )}
                                    <div className={styles.button}>
                                        <GoogleLoginButton
                                            fullWidth
                                            text={googleLoginTitle}
                                            routes={routes}
                                            setLoading={setLoading}
                                            createUser={false}
                                            afterOnlyLogin={() => {
                                                console.log('');
                                            }}
                                            tokenOnly={false}
                                        />
                                    </div>
                                    {(isiOS || isiOSWeb) && (
                                        <div className={styles.button}>
                                            <AppleLoginButton
                                                text={appleLoginTitle}
                                                routes={routes}
                                                setLoading={setLoading}
                                                createUser={false}
                                            />
                                        </div>
                                    )}
                                    <div className={styles.button}>
                                        <AzureLoginButton
                                            text={azureLoginTitle}
                                            routes={routes}
                                            setLoading={setLoading}
                                        />
                                    </div>
                                </form>
                            )}
                        />
                        {authenticationModule.isAuthenticationConfigured ? (
                            <section className={styles.AzureInfoText}>
                                {azureInfoText}
                            </section>
                        ) : null}
                    </Container>
                </Layout>
            )}
        </>
    );
};

export default LoginPage;
