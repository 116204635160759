import React, { useEffect, useState } from 'react';
import styles from './public-question-info-page.module.scss';
import { Language } from '_types/language';
import conversationClient from 'api/conversation-client';
import {
    returnCopyForLanguage,
    returnRoutesForLanguage,
} from 'services/language-service';
import { Link, useNavigate } from 'react-router-dom';
import { SEO } from 'components/seo';
import { Question } from '_types';
import { useSearchQuery } from 'hooks/use-search-query';
import { Loading } from 'components/loading';
import { Layout } from 'components/layout';
import { RouteList, routesEN, routesNO } from 'api/routes';
import { Button, Box } from 'components/base';

interface Props {
    language: Language;
}

interface ViewCopy {
    seoTitle: string;
    createUser: string;
    loginLabel: string;
    footerText: string;
}

const nbCopy: ViewCopy = {
    seoTitle: 'Spørsmål',
    createUser: 'Opprett bruker',
    loginLabel: 'Logg inn',
    footerText: 'Har du allerede en bruker?',
};

const enCopy: ViewCopy = {
    seoTitle: 'Questions',
    createUser: 'Create user',
    loginLabel: 'Log in',
    footerText: 'Do you already have a user?',
};

const copies: { name: Language; copy: ViewCopy }[] = [
    {
        name: 'nb',
        copy: nbCopy,
    },
    {
        name: 'en',
        copy: enCopy,
    },
];
const routeObj: { name: Language; routes: RouteList }[] = [
    {
        name: 'nb',
        routes: routesNO,
    },
    {
        name: 'en',
        routes: routesEN,
    },
];

const PublicQuestionInfoPage: React.FC<Props> = ({
    language,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const { seoTitle, createUser, loginLabel, footerText } =
        returnCopyForLanguage(language, copies);
    const [isLoading, setIsLoading] = useState(true);
    const [question, setQuestion] = useState<Question | null>(null);
    const routes = returnRoutesForLanguage(language, routeObj);
    const { id: questionId, token } = useSearchQuery();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (questionId) {
            Promise.all([
                conversationClient.getQuestion(
                    parseInt(questionId.toString()),
                    token!.toString()
                ),
            ])
                .then((response) => {
                    setQuestion(response[0]);
                    setIsLoading(false);
                })
                .catch(() => {
                    navigate(routes.error);
                });
        }
    }, [navigate, questionId, routes.error, token]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Layout
            headerProps={{ showLogoLink: true }}
            language={language}
            pageClassName={styles.Layout}
            routes={routes}
        >
            <div className={styles.QuestionInfoPageContainer}>
                <SEO title={seoTitle} />
                <div className={styles.bg} />

                <section className={styles.ContentContainer}>
                    <Box m='auto' alignItems='center' py={10}>
                        <h1 className={styles.QuestionSection}>
                            {question?.text}
                        </h1>
                    </Box>

                    <Button to={routes.createPremium}>{createUser}</Button>

                    <footer className={styles.footer}>
                        <span
                            className={styles.text}
                            data-content-mobile={'test'}
                            data-content-desktop={'deksop'}
                        >
                            {footerText}
                        </span>

                        <Link
                            className={styles.link}
                            to={routes.login}
                            data-testid='button-nav-login'
                        >
                            {loginLabel}
                        </Link>
                    </footer>
                </section>
            </div>
        </Layout>
    );
};

export default PublicQuestionInfoPage;
